import activity from './m-shopify.vue';

export default activity;

export const componentRegistrationData = {
  name: 'Shopify',
  icon: 'mdi-store',
  description: 'Submit a shopify backend link',
  status: true,
  setupRequired: false,
  styling: {
    borderColor: '#3c9ccc'
  },
  fields: [
    {
      roles: ['participant'],
      name: 'm-shopify',
      type: 'molecule',
      position: 0,
      preset: {},
      setup: {},
      value: {
        url: ''
      }
    },
    {
      roles: ['participant'],
      name: 'm-save-adk-only',
      type: 'molecule',
      position: 1,
      preset: {},
      setup: {},
      value: {}
    }
  ]
};
